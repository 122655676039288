import { components } from '@/lib/helpers.js'

//
// Fonts
//
import '@fontsource-variable/albert-sans'

//
// Styles
//
import '../css/style.css'

//
// Initialize components
//
components({
    header: import('@/components/header.js'),
    'embed-video': import('@/components/embed/video.js'),
    'embla-slider': import('@/components/slider.js'),
    'video-player': import('@/components/video-player.js'),
    'fancybox': import('@/components/fancybox.js'),
})

//
// Megamenu
//
import './megamenu'
import './menu.js'
